import { Q as T, B as we, V as Ce, U as R, S as D, J as X, G as Y, X as V, Y as ee, Z as te, _ as F, $ as ne, z as re, a0 as x, a1 as oe, a2 as ie, a3 as J, a4 as se, a5 as ce, a6 as ae, a7 as ue, q as z, T as de, p as Se, R as Ie, u as _e, P as A, g as q, C as B, n as fe, a8 as W } from "./plugin-BXacirOY.js";
async function Ee(t) {
  return new Promise((n) => setTimeout(n, t));
}
function ge(t, { delay: n = 100, retryCount: o = 2, shouldRetry: u = () => !0 } = {}) {
  return new Promise((c, i) => {
    const l = async ({ count: r = 0 } = {}) => {
      const s = async ({ error: e }) => {
        const a = typeof n == "function" ? n({ count: r, error: e }) : n;
        a && await Ee(a), l({ count: r + 1 });
      };
      try {
        const e = await t();
        c(e);
      } catch (e) {
        if (r < o && await u({ count: r, error: e }))
          return s({ error: e });
        i(e);
      }
    };
    l();
  });
}
function Re(t, n = {}) {
  return async (o, u = {}) => {
    const { retryDelay: c = 150, retryCount: i = 3 } = {
      ...n,
      ...u
    };
    return ge(async () => {
      try {
        return await t(o);
      } catch (l) {
        const r = l;
        switch (r.code) {
          case ue.code:
            throw new ue(r);
          case ae.code:
            throw new ae(r);
          case ce.code:
            throw new ce(r);
          case se.code:
            throw new se(r);
          case J.code:
            throw new J(r);
          case ie.code:
            throw new ie(r);
          case oe.code:
            throw new oe(r);
          case x.code:
            throw new x(r);
          case re.code:
            throw new re(r);
          case ne.code:
            throw new ne(r);
          case F.code:
            throw new F(r);
          case te.code:
            throw new te(r);
          case R.code:
            throw new R(r);
          case ee.code:
            throw new ee(r);
          case V.code:
            throw new V(r);
          case Y.code:
            throw new Y(r);
          case X.code:
            throw new X(r);
          case D.code:
            throw new D(r);
          case 5e3:
            throw new R(r);
          default:
            throw l instanceof we ? l : new Ce(r);
        }
      }
    }, {
      delay: ({ count: l, error: r }) => {
        if (r && r instanceof T) {
          const s = r?.headers?.get("Retry-After");
          if (s?.match(/\d/))
            return Number.parseInt(s) * 1e3;
        }
        return ~~(1 << l) * c;
      },
      retryCount: i,
      shouldRetry: ({ error: l }) => Pe(l)
    });
  };
}
function Pe(t) {
  return "code" in t && typeof t.code == "number" ? t.code === -1 || t.code === F.code || t.code === J.code : t instanceof T && t.status ? t.status === 403 || t.status === 408 || t.status === 413 || t.status === 429 || t.status === 500 || t.status === 502 || t.status === 503 || t.status === 504 : !0;
}
function Oe(t, { errorInstance: n = new Error("timed out"), timeout: o, signal: u }) {
  return new Promise((c, i) => {
    (async () => {
      let l;
      try {
        const r = new AbortController();
        o > 0 && (l = setTimeout(() => {
          u ? r.abort() : i(n);
        }, o)), c(await t({ signal: r?.signal || null }));
      } catch (r) {
        r?.name === "AbortError" && i(n), i(r);
      } finally {
        clearTimeout(l);
      }
    })();
  });
}
function je() {
  return {
    current: 0,
    take() {
      return this.current++;
    },
    reset() {
      this.current = 0;
    }
  };
}
const le = /* @__PURE__ */ je();
function Ae(t, n = {}) {
  return {
    async request(o) {
      const { body: u, onRequest: c = n.onRequest, onResponse: i = n.onResponse, timeout: l = n.timeout ?? 1e4 } = o, r = {
        ...n.fetchOptions ?? {},
        ...o.fetchOptions ?? {}
      }, { headers: s, method: e, signal: a } = r;
      try {
        const f = await Oe(async ({ signal: v }) => {
          const h = {
            ...r,
            body: Array.isArray(u) ? z(u.map((S) => ({
              jsonrpc: "2.0",
              id: S.id ?? le.take(),
              ...S
            }))) : z({
              jsonrpc: "2.0",
              id: u.id ?? le.take(),
              ...u
            }),
            headers: {
              ...s,
              "Content-Type": "application/json"
            },
            method: e || "POST",
            signal: a || (l > 0 ? v : null)
          }, w = new Request(t, h);
          return c && await c(w), await fetch(t, h);
        }, {
          errorInstance: new de({ body: u, url: t }),
          timeout: l,
          signal: !0
        });
        i && await i(f);
        let m;
        if (f.headers.get("Content-Type")?.startsWith("application/json") ? m = await f.json() : (m = await f.text(), m = JSON.parse(m || "{}")), !f.ok)
          throw new T({
            body: u,
            details: z(m.error) || f.statusText,
            headers: f.headers,
            status: f.status,
            url: t
          });
        return m;
      } catch (f) {
        throw f instanceof T || f instanceof de ? f : new T({
          body: u,
          details: f.message,
          url: t
        });
      }
    }
  };
}
const K = 256;
let L = K, M;
function Te(t = 11) {
  if (!M || L + t > K * 2) {
    M = "", L = 0;
    for (let n = 0; n < K; n++)
      M += (256 + Math.random() * 256 | 0).toString(16).substring(1);
  }
  return M.substring(L, L++ + t);
}
function xe(t) {
  const { batch: n, cacheTime: o = t.pollingInterval ?? 4e3, ccipRead: u, key: c = "base", name: i = "Base Client", pollingInterval: l = 4e3, type: r = "base" } = t, s = t.chain, e = t.account ? Se(t.account) : void 0, { config: a, request: f, value: m } = t.transport({
    chain: s,
    pollingInterval: l
  }), v = { ...a, ...m }, h = {
    account: e,
    batch: n,
    cacheTime: o,
    ccipRead: u,
    chain: s,
    key: c,
    name: i,
    pollingInterval: l,
    request: f,
    transport: v,
    type: r,
    uid: Te()
  };
  function w(d) {
    return (S) => {
      const y = S(d);
      for (const p in h)
        delete y[p];
      const C = { ...d, ...y };
      return Object.assign(C, { extend: w(C) });
    };
  }
  return Object.assign(h, { extend: w(h) });
}
function ke({ key: t, name: n, request: o, retryCount: u = 3, retryDelay: c = 150, timeout: i, type: l }, r) {
  return {
    config: { key: t, name: n, request: o, retryCount: u, retryDelay: c, timeout: i, type: l },
    request: Re(o, { retryCount: u, retryDelay: c }),
    value: r
  };
}
class qe extends we {
  constructor() {
    super("No URL was provided to the Transport. Please provide a valid RPC URL to the Transport.", {
      docsPath: "/docs/clients/intro"
    });
  }
}
function st(t, n = {}) {
  const { batch: o, fetchOptions: u, key: c = "http", name: i = "HTTP JSON-RPC", onFetchRequest: l, onFetchResponse: r, retryDelay: s } = n;
  return ({ chain: e, retryCount: a, timeout: f }) => {
    const { batchSize: m = 1e3, wait: v = 0 } = typeof o == "object" ? o : {}, h = n.retryCount ?? a, w = f ?? n.timeout ?? 1e4, d = t || e?.rpcUrls.default.http[0];
    if (!d)
      throw new qe();
    const S = Ae(d, {
      fetchOptions: u,
      onRequest: l,
      onResponse: r,
      timeout: w
    });
    return ke({
      key: c,
      name: i,
      async request({ method: y, params: C }) {
        const p = { method: y, params: C }, { schedule: g } = _e({
          id: d,
          wait: v,
          shouldSplitBatch(E) {
            return E.length > m;
          },
          fn: (E) => S.request({
            body: E
          }),
          sort: (E, O) => E.id - O.id
        }), b = async (E) => o ? g(E) : [
          await S.request({
            body: E
          })
        ], [{ error: _, result: I }] = await b(p);
        if (_)
          throw new Ie({
            body: p,
            error: _,
            url: d
          });
        return I;
      },
      retryCount: h,
      retryDelay: s,
      timeout: w,
      type: "http"
    }, {
      fetchOptions: u,
      url: d
    });
  };
}
function ct(t) {
  return t;
}
const Le = /(rabby|trustwallet)/, Me = {
  coinbaseWallet: {
    id: "coinbaseWallet",
    name: "Coinbase Wallet",
    provider(t) {
      return t?.coinbaseWalletExtension ? t.coinbaseWalletExtension : $(t, "isCoinbaseWallet");
    }
  },
  metaMask: {
    id: "metaMask",
    name: "MetaMask",
    provider(t) {
      return $(t, (n) => {
        if (!n.isMetaMask || n.isBraveWallet && !n._events && !n._state)
          return !1;
        const o = [
          "isApexWallet",
          "isAvalanche",
          "isBitKeep",
          "isBlockWallet",
          "isKuCoinWallet",
          "isMathWallet",
          "isOkxWallet",
          "isOKExWallet",
          "isOneInchIOSWallet",
          "isOneInchAndroidWallet",
          "isOpera",
          "isPortal",
          "isRabby",
          "isTokenPocket",
          "isTokenary",
          "isZerion"
        ];
        for (const u of o)
          if (n[u])
            return !1;
        return !0;
      });
    }
  },
  phantom: {
    id: "phantom",
    name: "Phantom",
    provider(t) {
      return t?.phantom?.ethereum ? t.phantom?.ethereum : $(t, "isPhantom");
    }
  }
};
Z.type = "injected";
function Z(t = {}) {
  const { shimDisconnect: n = !0, unstable_shimAsyncInject: o } = t;
  function u() {
    const s = t.target;
    if (typeof s == "function") {
      const e = s();
      if (e)
        return e;
    }
    return typeof s == "object" ? s : typeof s == "string" ? {
      ...Me[s] ?? {
        id: s,
        name: `${s[0].toUpperCase()}${s.slice(1)}`,
        provider: `is${s[0].toUpperCase()}${s.slice(1)}`
      }
    } : {
      id: "injected",
      name: "Injected",
      provider(e) {
        return e?.ethereum;
      }
    };
  }
  let c, i, l, r;
  return (s) => ({
    get icon() {
      return u().icon;
    },
    get id() {
      return u().id;
    },
    get name() {
      return u().name;
    },
    get supportsSimulation() {
      return Le.test(this.id.toLowerCase());
    },
    type: Z.type,
    async setup() {
      const e = await this.getProvider();
      e && t.target && (l || (l = this.onConnect.bind(this), e.on("connect", l)), c || (c = this.onAccountsChanged.bind(this), e.on("accountsChanged", c)));
    },
    async connect({ chainId: e, isReconnecting: a } = {}) {
      const f = await this.getProvider();
      if (!f)
        throw new A();
      let m = [];
      if (a)
        m = await this.getAccounts().catch(() => []);
      else if (n)
        try {
          m = (await f.request({
            method: "wallet_requestPermissions",
            params: [{ eth_accounts: {} }]
          }))[0]?.caveats?.[0]?.value?.map((h) => q(h));
        } catch (v) {
          const h = v;
          if (h.code === R.code)
            throw new R(h);
          if (h.code === x.code)
            throw h;
        }
      try {
        !m?.length && !a && (m = (await f.request({
          method: "eth_requestAccounts"
        })).map((w) => q(w))), l && (f.removeListener("connect", l), l = void 0), c || (c = this.onAccountsChanged.bind(this), f.on("accountsChanged", c)), i || (i = this.onChainChanged.bind(this), f.on("chainChanged", i)), r || (r = this.onDisconnect.bind(this), f.on("disconnect", r));
        let v = await this.getChainId();
        return e && v !== e && (v = (await this.switchChain({ chainId: e }).catch((w) => {
          if (w.code === R.code)
            throw w;
          return { id: v };
        }))?.id ?? v), n && await s.storage?.removeItem(`${this.id}.disconnected`), t.target || await s.storage?.setItem("injected.connected", !0), { accounts: m, chainId: v };
      } catch (v) {
        const h = v;
        throw h.code === R.code ? new R(h) : h.code === x.code ? new x(h) : h;
      }
    },
    async disconnect() {
      const e = await this.getProvider();
      if (!e)
        throw new A();
      i && (e.removeListener("chainChanged", i), i = void 0), r && (e.removeListener("disconnect", r), r = void 0), l || (l = this.onConnect.bind(this), e.on("connect", l));
      try {
        await e.request({
          // `'wallet_revokePermissions'` added in `viem@2.10.3`
          method: "wallet_revokePermissions",
          params: [{ eth_accounts: {} }]
        });
      } catch {
      }
      n && await s.storage?.setItem(`${this.id}.disconnected`, !0), t.target || await s.storage?.removeItem("injected.connected");
    },
    async getAccounts() {
      const e = await this.getProvider();
      if (!e)
        throw new A();
      return (await e.request({ method: "eth_accounts" })).map((f) => q(f));
    },
    async getChainId() {
      const e = await this.getProvider();
      if (!e)
        throw new A();
      const a = await e.request({ method: "eth_chainId" });
      return Number(a);
    },
    async getProvider() {
      if (typeof window > "u")
        return;
      let e;
      const a = u();
      return typeof a.provider == "function" ? e = a.provider(window) : typeof a.provider == "string" ? e = $(window, a.provider) : e = a.provider, e && !e.removeListener && ("off" in e && typeof e.off == "function" ? e.removeListener = e.off : e.removeListener = () => {
      }), e;
    },
    async isAuthorized() {
      try {
        if (n && // If shim exists in storage, connector is disconnected
        await s.storage?.getItem(`${this.id}.disconnected`) || !t.target && !await s.storage?.getItem("injected.connected"))
          return !1;
        if (!await this.getProvider()) {
          if (o !== void 0 && o !== !1) {
            const m = async () => (typeof window < "u" && window.removeEventListener("ethereum#initialized", m), !!await this.getProvider()), v = typeof o == "number" ? o : 1e3;
            if (await Promise.race([
              ...typeof window < "u" ? [
                new Promise((w) => window.addEventListener("ethereum#initialized", () => w(m()), { once: !0 }))
              ] : [],
              new Promise((w) => setTimeout(() => w(m()), v))
            ]))
              return !0;
          }
          throw new A();
        }
        return !!(await ge(() => this.getAccounts())).length;
      } catch {
        return !1;
      }
    },
    async switchChain({ addEthereumChainParameter: e, chainId: a }) {
      const f = await this.getProvider();
      if (!f)
        throw new A();
      const m = s.chains.find((v) => v.id === a);
      if (!m)
        throw new D(new B());
      try {
        return await Promise.all([
          f.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: fe(a) }]
          }).then(async () => {
            await this.getChainId() === a && s.emitter.emit("change", { chainId: a });
          }),
          new Promise((v) => s.emitter.once("change", ({ chainId: h }) => {
            h === a && v();
          }))
        ]), m;
      } catch (v) {
        const h = v;
        if (h.code === 4902 || // Unwrapping for MetaMask Mobile
        // https://github.com/MetaMask/metamask-mobile/issues/2944#issuecomment-976988719
        h?.data?.originalError?.code === 4902)
          try {
            const { default: w, ...d } = m.blockExplorers ?? {};
            let S;
            e?.blockExplorerUrls ? S = e.blockExplorerUrls : w && (S = [
              w.url,
              ...Object.values(d).map((g) => g.url)
            ]);
            let y;
            e?.rpcUrls?.length ? y = e.rpcUrls : y = [m.rpcUrls.default?.http[0] ?? ""];
            const C = {
              blockExplorerUrls: S,
              chainId: fe(a),
              chainName: e?.chainName ?? m.name,
              iconUrls: e?.iconUrls,
              nativeCurrency: e?.nativeCurrency ?? m.nativeCurrency,
              rpcUrls: y
            };
            if (await f.request({
              method: "wallet_addEthereumChain",
              params: [C]
            }), await this.getChainId() !== a)
              throw new R(new Error("User rejected switch after adding network."));
            return m;
          } catch (w) {
            throw new R(w);
          }
        throw h.code === R.code ? new R(h) : new D(h);
      }
    },
    async onAccountsChanged(e) {
      if (e.length === 0)
        this.onDisconnect();
      else if (s.emitter.listenerCount("connect")) {
        const a = (await this.getChainId()).toString();
        this.onConnect({ chainId: a }), n && await s.storage?.removeItem(`${this.id}.disconnected`);
      } else
        s.emitter.emit("change", {
          accounts: e.map((a) => q(a))
        });
    },
    onChainChanged(e) {
      const a = Number(e);
      s.emitter.emit("change", { chainId: a });
    },
    async onConnect(e) {
      const a = await this.getAccounts();
      if (a.length === 0)
        return;
      const f = Number(e.chainId);
      s.emitter.emit("connect", { accounts: a, chainId: f });
      const m = await this.getProvider();
      m && (l && (m.removeListener("connect", l), l = void 0), c || (c = this.onAccountsChanged.bind(this), m.on("accountsChanged", c)), i || (i = this.onChainChanged.bind(this), m.on("chainChanged", i)), r || (r = this.onDisconnect.bind(this), m.on("disconnect", r)));
    },
    async onDisconnect(e) {
      const a = await this.getProvider();
      e && e.code === 1013 && a && (await this.getAccounts()).length || (s.emitter.emit("disconnect"), a && (i && (a.removeListener("chainChanged", i), i = void 0), r && (a.removeListener("disconnect", r), r = void 0), l || (l = this.onConnect.bind(this), a.on("connect", l))));
    }
  });
}
function $(t, n) {
  function o(c) {
    return typeof n == "function" ? n(c) : typeof n == "string" ? c[n] : !0;
  }
  const u = t.ethereum;
  if (u?.providers)
    return u.providers.find((c) => o(c));
  if (u && o(u))
    return u;
}
function Ne(t) {
  const n = (o) => t(o.detail);
  return window.addEventListener("eip6963:announceProvider", n), window.dispatchEvent(new CustomEvent("eip6963:requestProvider")), () => window.removeEventListener("eip6963:announceProvider", n);
}
function Ue() {
  const t = /* @__PURE__ */ new Set();
  let n = [];
  const o = () => Ne((c) => {
    n.some(({ info: i }) => i.uuid === c.info.uuid) || (n = [...n, c], t.forEach((i) => i(n, { added: [c] })));
  });
  let u = o();
  return {
    _listeners() {
      return t;
    },
    clear() {
      t.forEach((c) => c([], { removed: [...n] })), n = [];
    },
    destroy() {
      this.clear(), t.clear(), u();
    },
    findProvider({ rdns: c }) {
      return n.find((i) => i.info.rdns === c);
    },
    getProviders() {
      return n;
    },
    reset() {
      this.clear(), u(), u = o();
    },
    subscribe(c, { emitImmediately: i } = {}) {
      return t.add(c), i && c(n, { added: n }), () => t.delete(c);
    }
  };
}
var $e = { VITE_WALLETCONNECT_PROJECT_ID: "7a4f8ad947d7cd10dfafdc64c69a267d", BASE_URL: "/", MODE: "production", DEV: !1, PROD: !0, SSR: !1 };
const De = (t) => (n, o, u) => {
  const c = u.subscribe;
  return u.subscribe = (l, r, s) => {
    let e = l;
    if (r) {
      const a = s?.equalityFn || Object.is;
      let f = l(u.getState());
      e = (m) => {
        const v = l(m);
        if (!a(f, v)) {
          const h = f;
          r(f = v, h);
        }
      }, s?.fireImmediately && r(f, f);
    }
    return c(e);
  }, t(n, o, u);
}, ze = De;
function We(t, n) {
  let o;
  try {
    o = t();
  } catch {
    return;
  }
  return {
    getItem: (c) => {
      var i;
      const l = (s) => s === null ? null : JSON.parse(s, void 0), r = (i = o.getItem(c)) != null ? i : null;
      return r instanceof Promise ? r.then(l) : l(r);
    },
    setItem: (c, i) => o.setItem(
      c,
      JSON.stringify(i, void 0)
    ),
    removeItem: (c) => o.removeItem(c)
  };
}
const k = (t) => (n) => {
  try {
    const o = t(n);
    return o instanceof Promise ? o : {
      then(u) {
        return k(u)(o);
      },
      catch(u) {
        return this;
      }
    };
  } catch (o) {
    return {
      then(u) {
        return this;
      },
      catch(u) {
        return k(u)(o);
      }
    };
  }
}, He = (t, n) => (o, u, c) => {
  let i = {
    getStorage: () => localStorage,
    serialize: JSON.stringify,
    deserialize: JSON.parse,
    partialize: (d) => d,
    version: 0,
    merge: (d, S) => ({
      ...S,
      ...d
    }),
    ...n
  }, l = !1;
  const r = /* @__PURE__ */ new Set(), s = /* @__PURE__ */ new Set();
  let e;
  try {
    e = i.getStorage();
  } catch {
  }
  if (!e)
    return t(
      (...d) => {
        console.warn(
          `[zustand persist middleware] Unable to update item '${i.name}', the given storage is currently unavailable.`
        ), o(...d);
      },
      u,
      c
    );
  const a = k(i.serialize), f = () => {
    const d = i.partialize({ ...u() });
    let S;
    const y = a({ state: d, version: i.version }).then(
      (C) => e.setItem(i.name, C)
    ).catch((C) => {
      S = C;
    });
    if (S)
      throw S;
    return y;
  }, m = c.setState;
  c.setState = (d, S) => {
    m(d, S), f();
  };
  const v = t(
    (...d) => {
      o(...d), f();
    },
    u,
    c
  );
  let h;
  const w = () => {
    var d;
    if (!e)
      return;
    l = !1, r.forEach((y) => y(u()));
    const S = ((d = i.onRehydrateStorage) == null ? void 0 : d.call(i, u())) || void 0;
    return k(e.getItem.bind(e))(i.name).then((y) => {
      if (y)
        return i.deserialize(y);
    }).then((y) => {
      if (y)
        if (typeof y.version == "number" && y.version !== i.version) {
          if (i.migrate)
            return i.migrate(
              y.state,
              y.version
            );
          console.error(
            "State loaded from storage couldn't be migrated since no migrate function was provided"
          );
        } else
          return y.state;
    }).then((y) => {
      var C;
      return h = i.merge(
        y,
        (C = u()) != null ? C : v
      ), o(h, !0), f();
    }).then(() => {
      S?.(h, void 0), l = !0, s.forEach((y) => y(h));
    }).catch((y) => {
      S?.(void 0, y);
    });
  };
  return c.persist = {
    setOptions: (d) => {
      i = {
        ...i,
        ...d
      }, d.getStorage && (e = d.getStorage());
    },
    clearStorage: () => {
      e?.removeItem(i.name);
    },
    getOptions: () => i,
    rehydrate: () => w(),
    hasHydrated: () => l,
    onHydrate: (d) => (r.add(d), () => {
      r.delete(d);
    }),
    onFinishHydration: (d) => (s.add(d), () => {
      s.delete(d);
    })
  }, w(), h || v;
}, Fe = (t, n) => (o, u, c) => {
  let i = {
    storage: We(() => localStorage),
    partialize: (w) => w,
    version: 0,
    merge: (w, d) => ({
      ...d,
      ...w
    }),
    ...n
  }, l = !1;
  const r = /* @__PURE__ */ new Set(), s = /* @__PURE__ */ new Set();
  let e = i.storage;
  if (!e)
    return t(
      (...w) => {
        console.warn(
          `[zustand persist middleware] Unable to update item '${i.name}', the given storage is currently unavailable.`
        ), o(...w);
      },
      u,
      c
    );
  const a = () => {
    const w = i.partialize({ ...u() });
    return e.setItem(i.name, {
      state: w,
      version: i.version
    });
  }, f = c.setState;
  c.setState = (w, d) => {
    f(w, d), a();
  };
  const m = t(
    (...w) => {
      o(...w), a();
    },
    u,
    c
  );
  let v;
  const h = () => {
    var w, d;
    if (!e)
      return;
    l = !1, r.forEach((y) => {
      var C;
      return y((C = u()) != null ? C : m);
    });
    const S = ((d = i.onRehydrateStorage) == null ? void 0 : d.call(i, (w = u()) != null ? w : m)) || void 0;
    return k(e.getItem.bind(e))(i.name).then((y) => {
      if (y)
        if (typeof y.version == "number" && y.version !== i.version) {
          if (i.migrate)
            return i.migrate(
              y.state,
              y.version
            );
          console.error(
            "State loaded from storage couldn't be migrated since no migrate function was provided"
          );
        } else
          return y.state;
    }).then((y) => {
      var C;
      return v = i.merge(
        y,
        (C = u()) != null ? C : m
      ), o(v, !0), a();
    }).then(() => {
      S?.(v, void 0), v = u(), l = !0, s.forEach((y) => y(v));
    }).catch((y) => {
      S?.(void 0, y);
    });
  };
  return c.persist = {
    setOptions: (w) => {
      i = {
        ...i,
        ...w
      }, w.storage && (e = w.storage);
    },
    clearStorage: () => {
      e?.removeItem(i.name);
    },
    getOptions: () => i,
    rehydrate: () => h(),
    hasHydrated: () => l,
    onHydrate: (w) => (r.add(w), () => {
      r.delete(w);
    }),
    onFinishHydration: (w) => (s.add(w), () => {
      s.delete(w);
    })
  }, i.skipHydration || h(), v || m;
}, Je = (t, n) => "getStorage" in n || "serialize" in n || "deserialize" in n ? (($e ? "production" : void 0) !== "production" && console.warn(
  "[DEPRECATED] `getStorage`, `serialize` and `deserialize` options are deprecated. Use `storage` option instead."
), He(t, n)) : Fe(t, n), Be = Je;
var Ke = { VITE_WALLETCONNECT_PROJECT_ID: "7a4f8ad947d7cd10dfafdc64c69a267d", BASE_URL: "/", MODE: "production", DEV: !1, PROD: !0, SSR: !1 };
const he = (t) => {
  let n;
  const o = /* @__PURE__ */ new Set(), u = (s, e) => {
    const a = typeof s == "function" ? s(n) : s;
    if (!Object.is(a, n)) {
      const f = n;
      n = e ?? typeof a != "object" ? a : Object.assign({}, n, a), o.forEach((m) => m(n, f));
    }
  }, c = () => n, r = { setState: u, getState: c, subscribe: (s) => (o.add(s), () => o.delete(s)), destroy: () => {
    (Ke ? "production" : void 0) !== "production" && console.warn(
      "[DEPRECATED] The `destroy` method will be unsupported in a future version. Instead use unsubscribe function returned by subscribe. Everything will be garbage-collected if store is garbage-collected."
    ), o.clear();
  } };
  return n = t(u, c, r), r;
}, H = (t) => t ? he(t) : he;
var at = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function Ge(t) {
  return t && t.__esModule && Object.prototype.hasOwnProperty.call(t, "default") ? t.default : t;
}
function ut(t) {
  if (t.__esModule) return t;
  var n = t.default;
  if (typeof n == "function") {
    var o = function u() {
      return this instanceof u ? Reflect.construct(n, arguments, this.constructor) : n.apply(this, arguments);
    };
    o.prototype = n.prototype;
  } else o = {};
  return Object.defineProperty(o, "__esModule", { value: !0 }), Object.keys(t).forEach(function(u) {
    var c = Object.getOwnPropertyDescriptor(t, u);
    Object.defineProperty(o, u, c.get ? c : {
      enumerable: !0,
      get: function() {
        return t[u];
      }
    });
  }), o;
}
var ve = { exports: {} };
(function(t) {
  var n = Object.prototype.hasOwnProperty, o = "~";
  function u() {
  }
  Object.create && (u.prototype = /* @__PURE__ */ Object.create(null), new u().__proto__ || (o = !1));
  function c(s, e, a) {
    this.fn = s, this.context = e, this.once = a || !1;
  }
  function i(s, e, a, f, m) {
    if (typeof a != "function")
      throw new TypeError("The listener must be a function");
    var v = new c(a, f || s, m), h = o ? o + e : e;
    return s._events[h] ? s._events[h].fn ? s._events[h] = [s._events[h], v] : s._events[h].push(v) : (s._events[h] = v, s._eventsCount++), s;
  }
  function l(s, e) {
    --s._eventsCount === 0 ? s._events = new u() : delete s._events[e];
  }
  function r() {
    this._events = new u(), this._eventsCount = 0;
  }
  r.prototype.eventNames = function() {
    var e = [], a, f;
    if (this._eventsCount === 0) return e;
    for (f in a = this._events)
      n.call(a, f) && e.push(o ? f.slice(1) : f);
    return Object.getOwnPropertySymbols ? e.concat(Object.getOwnPropertySymbols(a)) : e;
  }, r.prototype.listeners = function(e) {
    var a = o ? o + e : e, f = this._events[a];
    if (!f) return [];
    if (f.fn) return [f.fn];
    for (var m = 0, v = f.length, h = new Array(v); m < v; m++)
      h[m] = f[m].fn;
    return h;
  }, r.prototype.listenerCount = function(e) {
    var a = o ? o + e : e, f = this._events[a];
    return f ? f.fn ? 1 : f.length : 0;
  }, r.prototype.emit = function(e, a, f, m, v, h) {
    var w = o ? o + e : e;
    if (!this._events[w]) return !1;
    var d = this._events[w], S = arguments.length, y, C;
    if (d.fn) {
      switch (d.once && this.removeListener(e, d.fn, void 0, !0), S) {
        case 1:
          return d.fn.call(d.context), !0;
        case 2:
          return d.fn.call(d.context, a), !0;
        case 3:
          return d.fn.call(d.context, a, f), !0;
        case 4:
          return d.fn.call(d.context, a, f, m), !0;
        case 5:
          return d.fn.call(d.context, a, f, m, v), !0;
        case 6:
          return d.fn.call(d.context, a, f, m, v, h), !0;
      }
      for (C = 1, y = new Array(S - 1); C < S; C++)
        y[C - 1] = arguments[C];
      d.fn.apply(d.context, y);
    } else {
      var p = d.length, g;
      for (C = 0; C < p; C++)
        switch (d[C].once && this.removeListener(e, d[C].fn, void 0, !0), S) {
          case 1:
            d[C].fn.call(d[C].context);
            break;
          case 2:
            d[C].fn.call(d[C].context, a);
            break;
          case 3:
            d[C].fn.call(d[C].context, a, f);
            break;
          case 4:
            d[C].fn.call(d[C].context, a, f, m);
            break;
          default:
            if (!y) for (g = 1, y = new Array(S - 1); g < S; g++)
              y[g - 1] = arguments[g];
            d[C].fn.apply(d[C].context, y);
        }
    }
    return !0;
  }, r.prototype.on = function(e, a, f) {
    return i(this, e, a, f, !1);
  }, r.prototype.once = function(e, a, f) {
    return i(this, e, a, f, !0);
  }, r.prototype.removeListener = function(e, a, f, m) {
    var v = o ? o + e : e;
    if (!this._events[v]) return this;
    if (!a)
      return l(this, v), this;
    var h = this._events[v];
    if (h.fn)
      h.fn === a && (!m || h.once) && (!f || h.context === f) && l(this, v);
    else {
      for (var w = 0, d = [], S = h.length; w < S; w++)
        (h[w].fn !== a || m && !h[w].once || f && h[w].context !== f) && d.push(h[w]);
      d.length ? this._events[v] = d.length === 1 ? d[0] : d : l(this, v);
    }
    return this;
  }, r.prototype.removeAllListeners = function(e) {
    var a;
    return e ? (a = o ? o + e : e, this._events[a] && l(this, a)) : (this._events = new u(), this._eventsCount = 0), this;
  }, r.prototype.off = r.prototype.removeListener, r.prototype.addListener = r.prototype.on, r.prefixed = o, r.EventEmitter = r, t.exports = r;
})(ve);
var Ze = ve.exports;
const Qe = /* @__PURE__ */ Ge(Ze);
class Xe {
  constructor(n) {
    Object.defineProperty(this, "uid", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: n
    }), Object.defineProperty(this, "_emitter", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: new Qe()
    });
  }
  on(n, o) {
    this._emitter.on(n, o);
  }
  once(n, o) {
    this._emitter.once(n, o);
  }
  off(n, o) {
    this._emitter.off(n, o);
  }
  emit(n, ...o) {
    const u = o[0];
    this._emitter.emit(n, { uid: this.uid, ...u });
  }
  listenerCount(n) {
    return this._emitter.listenerCount(n);
  }
}
function Ye(t) {
  return new Xe(t);
}
function Ve(t, n) {
  return JSON.parse(t, (o, u) => {
    let c = u;
    return c?.__type === "bigint" && (c = BigInt(c.value)), c?.__type === "Map" && (c = new Map(c.value)), n?.(o, c) ?? c;
  });
}
function pe(t, n) {
  return t.slice(0, n).join(".") || ".";
}
function me(t, n) {
  const { length: o } = t;
  for (let u = 0; u < o; ++u)
    if (t[u] === n)
      return u + 1;
  return 0;
}
function et(t, n) {
  const o = typeof t == "function", u = typeof n == "function", c = [], i = [];
  return function(r, s) {
    if (typeof s == "object")
      if (c.length) {
        const e = me(c, this);
        e === 0 ? c[c.length] = this : (c.splice(e), i.splice(e)), i[i.length] = r;
        const a = me(c, s);
        if (a !== 0)
          return u ? n.call(this, r, s, pe(i, a)) : `[ref=${pe(i, a)}]`;
      } else
        c[0] = s, i[0] = r;
    return o ? t.call(this, r, s) : s;
  };
}
function tt(t, n, o, u) {
  return JSON.stringify(t, et((c, i) => {
    let l = i;
    return typeof l == "bigint" && (l = { __type: "bigint", value: i.toString() }), l instanceof Map && (l = { __type: "Map", value: Array.from(i.entries()) }), n?.(c, l) ?? l;
  }, u), o ?? void 0);
}
function nt(t) {
  const { deserialize: n = Ve, key: o = "wagmi", serialize: u = tt, storage: c = ye } = t;
  function i(l) {
    return l instanceof Promise ? l.then((r) => r).catch(() => null) : l;
  }
  return {
    ...c,
    key: o,
    async getItem(l, r) {
      const s = c.getItem(`${o}.${l}`), e = await i(s);
      return e ? n(e) ?? null : r ?? null;
    },
    async setItem(l, r) {
      const s = `${o}.${l}`;
      r === null ? await i(c.removeItem(s)) : await i(c.setItem(s, u(r)));
    },
    async removeItem(l) {
      await i(c.removeItem(`${o}.${l}`));
    }
  };
}
const ye = {
  getItem: () => null,
  setItem: () => {
  },
  removeItem: () => {
  }
}, G = 256;
let N = G, U;
function rt(t = 11) {
  if (!U || N + t > G * 2) {
    U = "", N = 0;
    for (let n = 0; n < G; n++)
      U += (256 + Math.random() * 256 | 0).toString(16).substring(1);
  }
  return U.substring(N, N++ + t);
}
function dt(t) {
  const { multiInjectedProviderDiscovery: n = !0, storage: o = nt({
    storage: typeof window < "u" && window.localStorage ? window.localStorage : ye
  }), syncConnectedChain: u = !0, ssr: c, ...i } = t, l = typeof window < "u" && n ? Ue() : void 0, r = H(() => i.chains), s = H(() => [
    ...i.connectors ?? [],
    ...c ? [] : l?.getProviders().map(a) ?? []
  ].map(e));
  function e(p) {
    const g = Ye(rt()), b = {
      ...p({ emitter: g, chains: r.getState(), storage: o }),
      emitter: g,
      uid: g.uid
    };
    return g.on("connect", y), b.setup?.(), b;
  }
  function a(p) {
    const { info: g } = p, b = p.provider;
    return Z({ target: { ...g, id: g.rdns, provider: b } });
  }
  const f = /* @__PURE__ */ new Map();
  function m(p = {}) {
    const g = p.chainId ?? d.getState().chainId, b = r.getState().find((I) => I.id === g);
    if (p.chainId && !b)
      throw new B();
    {
      const I = f.get(d.getState().chainId);
      if (I && !b)
        return I;
      if (!b)
        throw new B();
    }
    {
      const I = f.get(g);
      if (I)
        return I;
    }
    let _;
    if (i.client)
      _ = i.client({ chain: b });
    else {
      const I = b.id, E = r.getState().map((P) => P.id), O = {}, Q = Object.entries(i);
      for (const [P, j] of Q)
        if (!(P === "chains" || P === "client" || P === "connectors" || P === "transports"))
          if (typeof j == "object")
            if (I in j)
              O[P] = j[I];
            else {
              if (E.some((be) => be in j))
                continue;
              O[P] = j;
            }
          else
            O[P] = j;
      _ = xe({
        ...O,
        chain: b,
        batch: O.batch ?? { multicall: !0 },
        transport: (P) => i.transports[I]({ ...P, connectors: s })
      });
    }
    return f.set(g, _), _;
  }
  function v() {
    return {
      chainId: r.getState()[0].id,
      connections: /* @__PURE__ */ new Map(),
      current: null,
      status: "disconnected"
    };
  }
  let h;
  const w = "0.0.0-canary-";
  W.startsWith(w) ? h = Number.parseInt(W.replace(w, "")) : h = Number.parseInt(W.split(".")[0] ?? "0");
  const d = H(ze(
    // only use persist middleware if storage exists
    o ? Be(v, {
      migrate(p, g) {
        if (g === h)
          return p;
        const b = v(), _ = p && typeof p == "object" && "chainId" in p && typeof p.chainId == "number" ? p.chainId : b.chainId;
        return { ...b, chainId: _ };
      },
      name: "store",
      partialize(p) {
        return {
          connections: {
            __type: "Map",
            value: Array.from(p.connections.entries()).map(([g, b]) => {
              const { id: _, name: I, type: E, uid: O } = b.connector;
              return [g, { ...b, connector: { id: _, name: I, type: E, uid: O } }];
            })
          },
          chainId: p.chainId,
          current: p.current
        };
      },
      skipHydration: c,
      storage: o,
      version: h
    }) : v
  ));
  u && d.subscribe(({ connections: p, current: g }) => g ? p.get(g)?.chainId : void 0, (p) => {
    if (r.getState().some((b) => b.id === p))
      return d.setState((b) => ({
        ...b,
        chainId: p ?? b.chainId
      }));
  }), l?.subscribe((p) => {
    const g = /* @__PURE__ */ new Map();
    for (const _ of s.getState())
      g.set(_.id, !0);
    const b = [];
    for (const _ of p) {
      const I = e(a(_));
      g.has(I.id) || b.push(I);
    }
    o && !d.persist.hasHydrated() || s.setState((_) => [..._, ...b], !0);
  });
  function S(p) {
    d.setState((g) => {
      const b = g.connections.get(p.uid);
      return b ? {
        ...g,
        connections: new Map(g.connections).set(p.uid, {
          accounts: p.accounts ?? b.accounts,
          chainId: p.chainId ?? b.chainId,
          connector: b.connector
        })
      } : g;
    });
  }
  function y(p) {
    d.getState().status === "connecting" || d.getState().status === "reconnecting" || d.setState((g) => {
      const b = s.getState().find((_) => _.uid === p.uid);
      return b ? (b.emitter.listenerCount("connect") && b.emitter.off("connect", S), b.emitter.listenerCount("change") || b.emitter.on("change", S), b.emitter.listenerCount("disconnect") || b.emitter.on("disconnect", C), {
        ...g,
        connections: new Map(g.connections).set(p.uid, {
          accounts: p.accounts,
          chainId: p.chainId,
          connector: b
        }),
        current: p.uid,
        status: "connected"
      }) : g;
    });
  }
  function C(p) {
    d.setState((g) => {
      const b = g.connections.get(p.uid);
      if (b) {
        const I = b.connector;
        I.emitter.listenerCount("change") && b.connector.emitter.off("change", S), I.emitter.listenerCount("disconnect") && b.connector.emitter.off("disconnect", C), I.emitter.listenerCount("connect") || b.connector.emitter.on("connect", y);
      }
      if (g.connections.delete(p.uid), g.connections.size === 0)
        return {
          ...g,
          connections: /* @__PURE__ */ new Map(),
          current: null,
          status: "disconnected"
        };
      const _ = g.connections.values().next().value;
      return {
        ...g,
        connections: new Map(g.connections),
        current: _.connector.uid
      };
    });
  }
  return {
    get chains() {
      return r.getState();
    },
    get connectors() {
      return s.getState();
    },
    storage: o,
    getClient: m,
    get state() {
      return d.getState();
    },
    setState(p) {
      let g;
      typeof p == "function" ? g = p(d.getState()) : g = p;
      const b = v();
      typeof g != "object" && (g = b), Object.keys(b).some((I) => !(I in g)) && (g = b), d.setState(g, !0);
    },
    subscribe(p, g, b) {
      return d.subscribe(p, g, b ? { ...b, fireImmediately: b.emitImmediately } : void 0);
    },
    _internal: {
      mipd: l,
      store: d,
      ssr: !!c,
      syncConnectedChain: u,
      transports: i.transports,
      chains: {
        setState(p) {
          const g = typeof p == "function" ? p(r.getState()) : p;
          if (g.length !== 0)
            return r.setState(g, !0);
        },
        subscribe(p) {
          return r.subscribe(p);
        }
      },
      connectors: {
        providerDetailToConnector: a,
        setup: e,
        setState(p) {
          return s.setState(typeof p == "function" ? p(s.getState()) : p, !0);
        },
        subscribe(p) {
          return s.subscribe(p);
        }
      },
      events: { change: S, connect: y, disconnect: C }
    }
  };
}
export {
  Qe as O,
  qe as U,
  dt as a,
  le as b,
  ct as c,
  Oe as d,
  ge as e,
  ke as f,
  Ge as g,
  st as h,
  Z as i,
  xe as j,
  Ve as k,
  nt as l,
  at as m,
  ye as n,
  Ze as o,
  ut as p,
  tt as s,
  Ee as w
};
